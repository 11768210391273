import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Item from "src/pages/Item";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CountryManagementPage from "./pages/Country";
import CategoryManagementPage from "./pages/Category";
import MenuBar from "./components/commons/MenuBar";
import AuthProvider from "./providers/AuthProvider";
import Login from "./pages/Login";
import AddItem from "./pages/Item/AddItem";
import EditItem from "./pages/Item/EditItem";
import ResourceLinkNameManagementPage from "./pages/ResourceLinkName";

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <AuthProvider>
          <MenuBar />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Item />} />
            <Route path="/item" element={<Item />} />
            <Route path="/item/add" element={<AddItem />} />
            <Route path="/item/edit/:id" element={<EditItem />} />
            <Route path="/country" element={<CountryManagementPage />} />
            <Route path="/category" element={<CategoryManagementPage />} />
            <Route path="/resource-link-name" element={<ResourceLinkNameManagementPage />} />
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
