import { useContext } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/providers/AuthProvider";

const MenuBar = () => {
  const { status, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Navbar bg="primary" variant="dark" expand="lg" className="mb-5 ml-auto">
      <Navbar.Brand onClick={() => navigate("/")} className="px-3">
        Admin Panel
      </Navbar.Brand>
      <Navbar.Toggle className="px-3" aria-controls="navbarNav" />
      <Navbar.Collapse
        className="px-3 d-lg-flex justify-content-lg-between"
        id="navbarNav"
      >
        <Nav className="mr-auto">
          <Nav.Link onClick={() => navigate("/item")}>Item</Nav.Link>
          <Nav.Link onClick={() => navigate("/category")}>Category</Nav.Link>
          <Nav.Link onClick={() => navigate("/country")}>Country</Nav.Link>
          <Nav.Link onClick={() => navigate("/resource-link-name")}>ResourceLinkName</Nav.Link>
        </Nav>
        {status.isAuthenticated && (
          <Nav>
            <Button variant="outline-light" onClick={logout}>
              Logout
            </Button>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MenuBar;
