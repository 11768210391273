import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import api from "src/api";
import { Button, FormGroup, Input, Select } from "src/components/utils";
import { ResourceLinkType } from "src/constants/utils";
import ResourceLinkModel from "src/models/app/ResourceLink";
import ResourceLinkName from "src/models/app/ResourceLinkName";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setResourceLinks: React.Dispatch<
    React.SetStateAction<{ type: ResourceLinkType;
      resource_link: string;
      resource_link_name_id: number | null;
      displayName : string }[]>
  >;
}

function ResourceLink({ showModal, setShowModal, setResourceLinks }: Props) {
  const [formData, setFormData] = useState<{
    type: ResourceLinkType;
    resource_link: string;
    resource_link_name_id: number | null;
    displayName : string
  }>({
    type: ResourceLinkType.DOWNLOAD,
    resource_link: "",
    resource_link_name_id: null,
    displayName : ""
  });
  const [resourceLinkNames, setResourceLinkNames] = useState<
    ResourceLinkName[]
  >([]);

  // start - effect
  useEffect(() => {
    fetchResourceLinkNames();
  }, []);


  useEffect(() => {
    if (resourceLinkNames.length > 0) {
      const resourceLinkName = resourceLinkNames[0]
      setFormData({
        ...formData,
        resource_link_name_id : resourceLinkName.id,
        displayName: resourceLinkName.display_name
      })
    }
  }, [resourceLinkNames])
  // end - effect

  // start - api
  const fetchResourceLinkNames = async () => {
    try {
      const response = await api.get("/resource-link-names");
      setResourceLinkNames(response.data);
    } catch (error) {
      console.error("Error fetching : ", error);
    }
  };
  // end - api

  // start - utils
  const closeModal = () => {
    setShowModal(false);
    setFormData({
      ...formData,
      resource_link: "",
      type: ResourceLinkType.DOWNLOAD,
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.name === "resource_link_name_id") {
      const resourceLinkName =  resourceLinkNames.find(resourceLinkName => resourceLinkName.id === parseInt(e.target.value));
      resourceLinkName && setFormData({
        ...formData,
        displayName: resourceLinkName.display_name,
        resource_link_name_id : resourceLinkName.id
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    
  };

  const save = () => {
    setResourceLinks((prev) => [...prev, formData]);
    closeModal();
  };
  // end - utils

  return (
    <>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Resource Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            label="Resource Link"
            formField={
              <Input
                name="resource_link"
                onChange={handleInputChange}
                value={formData.resource_link}
              />
            }
          />

          <FormGroup
            label="Type"
            formField={
              <Select
                value={formData.type}
                name="type"
                onChange={handleInputChange}
              >
                <option value={ResourceLinkType.DOWNLOAD}>Download</option>
                <option value={ResourceLinkType.STREAMING}>Streaming</option>
                <option value={ResourceLinkType.EMBED}>Iframe</option>
              </Select>
            }
          />

          <FormGroup
            label="Display Name"
            formField={
              <Select
                value={formData.resource_link_name_id ?? ""}
                name="resource_link_name_id"
                onChange={handleInputChange}
              >
                {resourceLinkNames.map((resourceLinkName) => (
                  <option key={resourceLinkName.id} value={resourceLinkName.id}>
                    {resourceLinkName.display_name}
                  </option>
                ))}
              </Select>
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={save}>Save changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ResourceLink;
