export enum ItemType {
    FILM = "film",
    SERIES = "series"
}

export enum SubType {
    MM_SUB = "mmsub",
    NO_SUB = "nosub"
}

export enum ResourceLinkType {
    DOWNLOAD = "download",
    STREAMING = "streaming",
    EMBED = "embed"
}

export enum Utils {
    PAGINATION_COUNT_PER_PAGE = 10
}