import React, { useContext, useState } from "react";
import { Input, Button, FormGroup } from "src/components/utils";
import { toast } from "react-toastify";
import api from "src/api";
import { AuthContext } from "src/providers/AuthProvider";
import Cookies from "js-cookie";
import Loading from "src/components/commons/Loading";
import styles from "./index.module.css"

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Track whether the password is visible or not
  const { status } = useContext(AuthContext);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); // Toggle the showPassword state
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    // Perform login logic here
    if (email && password) {
      try {
        const res = await api.post("/admin/login", {
          email,
          password,
        });
        console.log("res", res);
        toast.success("Login successful");
        Cookies.set("credentials", JSON.stringify(res.data.user));
        status.setIsAuthenticated(true);
      } catch (error) {
        console.log("error : ", error);
        toast.error("Login failed.");
      }
    } else {
      toast.error("Required email or password");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading isFullScreen={true} />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-sm-10 offset-sm-1">
              <h2>Login</h2>
              <form onSubmit={handleSubmit}>
                <FormGroup
                  label={"Email"}
                  formField={
                    <Input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Enter your email"
                    />
                  }
                />

                <FormGroup
                  label={"Password"}
                  formField={
                    <div className={styles.passwordInputWrapper}>
                      <Input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter your password"
                      />
                      <i
                        className={`password-toggle-icon ${
                          showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                        }`}
                        onClick={handleTogglePasswordVisibility}
                      ></i>
                    </div>
                  }
                />

                <div className="mt-2">
                  <Button type="submit">Login</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
