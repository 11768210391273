import Cookies from "js-cookie";
import React, { ReactNode, createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "src/components/commons/Loading";

interface AuthContextType {
  status: {
    isAuthenticated: boolean | null;
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean | null>>;
  };
  logout : () => void
}

export const AuthContext = createContext<AuthContextType>({
  status: {
    isAuthenticated: null,
    setIsAuthenticated: () => {},
  },
  logout : () => {}
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // effect - start
  useEffect(() => {
    if (isAuthenticated === null) {
      const userInfo = Cookies.get('credentials');

      if (userInfo) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      isAuthenticated ? ( location.pathname.includes("login") ? navigate("/") : navigate(location.pathname)) : navigate("/login")
    }
  }, [isAuthenticated, location.pathname]);
  // effect - end

  // utils - start
    const logout = () => {
      Cookies.remove('credentials');
      window.location.reload();
    }
  // utils - end

  return (
    <AuthContext.Provider
      value={{ status: { isAuthenticated, setIsAuthenticated }, logout }}
    >
      {isAuthenticated === null ? <Loading isFullScreen={true} /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
