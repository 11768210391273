import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you may need, such as authorization token
  },
});

export default api;
