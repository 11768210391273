import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "src/api";
import { Button } from "src/components/utils";
import {
  ItemType,
  ResourceLinkType,
  SubType,
  Utils,
} from "src/constants/utils";
import Category from "src/models/app/Category";
import Country from "src/models/app/Country";
import Item from "src/models/app/Item";
import { toast } from "react-toastify";
import CustomPagination from "src/components/commons/Pagination";
import Loading from "src/components/commons/Loading";
import "react-quill/dist/quill.snow.css";
import { Link, NavLink } from "react-router-dom";

const ItemManagementPage: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [formData, setFormData] = useState<Item>({
    id: 0,
    type: ItemType.FILM,
    sub_type: SubType.MM_SUB,
    country_id: 0,
    category_id: 0,
    title: "",
    short_description: "",
    full_description: "",
    resource_links: [],
  });
  const [infoUtils, setInfoUtils] = useState<{
    country: Country[] | null;
    category: Category[] | null;
  }>({
    country: null,
    category: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const itemsPerPage = Utils.PAGINATION_COUNT_PER_PAGE;
  const containerRef = useRef<HTMLDivElement>(null);

  // start - effect
  useEffect(() => {
    fetchItemsInfo();
    fetchCategory();
    fetchCountry();
  }, []);

  useEffect(() => {
    fetchItems();
  }, [currentPage]);

  useEffect(() => {
    setDefaultValues();
  }, [infoUtils]);
  // end - effect

  // start - api
  const fetchItemsInfo = async () => {
    try {
      const response = await api.get(`/items/info`);
      setTotalItemsCount(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching items count:", error);
    }
  };

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/items?page=${currentPage}&per_page=${itemsPerPage}`
      );
      setItems(response.data.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
    setIsLoading(false);
  };

  const fetchCountry = async () => {
    try {
      const response = await api.get("/countries");
      setInfoUtils((prevOptions) => ({
        ...prevOptions,
        country: response.data,
      }));
    } catch (error) {
      console.error("Error fetching country:", error);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await api.get("/categories");
      setInfoUtils((prevOptions) => ({
        ...prevOptions,
        category: response.data,
      }));
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };
  // end - api

  // start - utils
  const setDefaultValues = useCallback(() => {
    if (infoUtils.category && infoUtils.country) {
      const country = infoUtils.country.find((_, i) => i === 0);
      const category = infoUtils.category.find((_, i) => i === 0);
      setFormData((prev) => ({
        ...prev,
        id: 0,
        title: "",
        short_description: "",
        full_description: "",
        resource_links: [],
        type: ItemType.FILM,
        sub_type: SubType.MM_SUB,
        country_id: country ? country.id : 0,
        category_id: category ? category.id : 0,
      }));
    }
  }, [infoUtils]);

  const handleDelete = async (itemId: number) => {
    try {
      await api.delete(`/admin/items/${itemId}`);
      toast.success("Deleted successfully.");
      fetchItems();
    } catch (error) {
      toast.error("Something went wrong.");
      console.error("Error deleting item:", error);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  // end - utils

  return (
    <>
      {isLoading ? (
        <Loading isFullScreen={true} />
      ) : (
        <div className="container" ref={containerRef}>
          <div className="d-flex align-items-center justify-content-between">
            <h1>Item Management</h1>
            <Link to={"/item/add"}>
              <Button>Add New</Button>
            </Link>
          </div>

          <div className="table-responsive">
            <table className="mt-5 table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Cover Image</th>
                  <th>Title</th>
                  <th>Is VIP</th>
                  <th>Type</th>
                  <th>Sub Type</th>
                  <th>Country ID</th>
                  <th>Category ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <img
                        src={item.cover_img}
                        style={{ width: "200px", height: "200px" }}
                        alt="photo"
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>{item.resource_links.length > 0 ? <span className="text-info">NO</span> : <b className="text-success">YES</b> }</td>
                    <td>{item.type}</td>
                    <td>{item.sub_type}</td>
                    <td>
                      {
                        infoUtils.country?.find(
                          (country) => country.id === item.country_id
                        )?.name
                      }
                    </td>
                    <td>
                      {
                        infoUtils.category?.find(
                          (category) => category.id === item.category_id
                        )?.name
                      }
                    </td>
                    <td className="d-flex align-items-center">
                      <NavLink to={`/item/edit/${item.id}`}><Button>Edit</Button></NavLink>
                      <Button
                        className="ms-3 btn-outline-danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <CustomPagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItemsCount}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
          <hr />
        </div>
      )}
    </>
  );
};

export default ItemManagementPage;
