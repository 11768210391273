import React from "react";
import Props from "./index.type";
import Label from "../Label";

function FormGroup(props: Props) {
  const { label, formField, required = false, validationErrorMessage} = props;

  return (
    <div className="form-group">
      <div className="py-2">
        <div>
        {typeof label === "string" ? <Label>{label}</Label> : label}
        {required && <b style={{color: "red"}}>*</b>}
        </div>
        <div>{formField}</div>
        {validationErrorMessage && <span style={{color: "red"}}>{validationErrorMessage}</span>}
      </div>
    </div>
  );
}

export default FormGroup;
