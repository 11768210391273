import React, { useEffect, useRef, useState } from "react";
import api from "src/api"; // Assuming you have created an Axios instance named 'api'
import { Button, FormGroup, Input, Label, Select } from "src/components/utils";
import ResourceLinkName from "src/models/app/ResourceLinkName";
import { toast } from "react-toastify";
import { camelToSnake } from "src/utils/stringUtils";

const ResourceLinkNameManagementPage: React.FC = () => {
  const [resourceLinkNames, setResourceLinkNames] = useState<
    ResourceLinkName[]
  >([]);
  const [formData, setFormData] = useState<ResourceLinkName>({
    id: 0,
    display_name: "",
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchResourceLinkNames();
  }, []);

  const fetchResourceLinkNames = async () => {
    try {
      const response = await api.get("/resource-link-names");
      setResourceLinkNames(response.data);
    } catch (error) {
      console.error("Error fetching resourceLinkNames:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.display_name) {
      toast.error("Please fill the resourceLink name");
      return;
    }

    try {
      if (formData.id) {
        await api.put(`/admin/resource-link-names/${formData.id}`, formData);
        toast.success("Data updated successfully");
      } else {
        await api.post("/admin/resource-link-names", formData);
        toast.success("Data created successfully");
      }
      fetchResourceLinkNames();
      resetForm();
    } catch (error) {
      console.error("Error saving:", error);
    }
  };

  const handleEdit = (resourceLinkName: ResourceLinkName) => {
    setFormData(resourceLinkName);
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/admin/resource-link-names/${id}`);
      toast.success("Deleted successfully.");
      fetchResourceLinkNames();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      id: 0,
      display_name: "",
    });
  };

  return (
    <div className="container" ref={containerRef}>
      <h1>Resource Link Name Management</h1>

      <div className="table-responsive">
        <table className="mt-5 table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {resourceLinkNames.map((resourceLinkName) => (
              <tr key={resourceLinkName.id}>
                <td>{resourceLinkName.id}</td>
                <td>{resourceLinkName.display_name}</td>
                <td className="d-flex align-items-center">
                  <Button onClick={() => handleEdit(resourceLinkName)}>
                    Edit
                  </Button>
                  {/* <Button
                    className="ms-3 btn-outline-danger"
                    onClick={() => handleDelete(resourceLinkName.id)}
                  >
                    Delete
                  </Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />

      <form onSubmit={handleSubmit}>
        <FormGroup
          label="Name"
          required
          formField={
            <Input
              name="display_name"
              onChange={handleInputChange}
              value={formData.display_name}
            />
          }
        />

        <div className="my-5">
          <Button type="submit">{formData.id ? "Update" : "Create"}</Button>
          <Button
            type="button"
            className="btn-outline-secondary ms-3"
            onClick={resetForm}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResourceLinkNameManagementPage;
