import React, { useEffect, useRef, useState } from "react";
import api from "src/api"; // Assuming you have created an Axios instance named 'api'
import { Button, FormGroup, Input, Label, Select } from "src/components/utils";
import Category from "src/models/app/Category";
import Country from "src/models/app/Country";
import { toast } from "react-toastify";

const CountryManagementPage: React.FC = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [formData, setFormData] = useState<Country>({
    id: 0,
    name: "",
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await api.get("/countries");
      setCountries(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.name) {
      toast.error("Please fill the country name");
      return;
    }

    try {
      if (formData.id) {
        await api.put(`/admin/countries/${formData.id}`, formData);
        toast.success("Country updated successfully");
      } else {
        await api.post("/admin/countries", formData);
        toast.success("Country created successfully");
      }
      fetchCountries();
      resetForm();
    } catch (error) {
      console.error("Error saving country:", error);
    }
  };

  const handleEdit = (country: Country) => {
    setFormData(country);
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }   
  };

  const handleDelete = async (countryId: number) => {
    try {
      await api.delete(`/admin/countries/${countryId}`);
      toast.success("Deleted successfully.")
      fetchCountries();
    } catch (error) {
      console.error("Error deleting country:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      id: 0,
      name: "",
    });
  };

  return (
    <div className="container" ref={containerRef}>
      <h1>Country Management</h1>

      <div className="table-responsive">
        <table className="mt-5 table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {countries.map((country) => (
              <tr key={country.id}>
                <td>{country.id}</td>
                <td>{country.name}</td>
                <td className="d-flex align-items-center">
                  <Button onClick={() => handleEdit(country)}>Edit</Button>
                  {/* <Button
                    className="ms-3 btn-outline-danger"
                    onClick={() => handleDelete(country.id)}
                  >
                    Delete
                  </Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />

      <form onSubmit={handleSubmit}>
        <FormGroup
          label="Name"
          required
          formField={
            <Input
              name="name"
              onChange={handleInputChange}
              value={formData.name}
            />
          }
        />

        <div className="my-5">
          <Button type="submit">
            {formData.id ? "Update Country" : "Create Country"}
          </Button>
          <Button
            type="button"
            className="btn-outline-secondary ms-3"
            onClick={resetForm}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CountryManagementPage;