import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import api from "src/api";
import { Checkbox, FormGroup, Input, Select } from "src/components/utils";
import { ItemType, ResourceLinkType, SubType } from "src/constants/utils";
import Category from "src/models/app/Category";
import Country from "src/models/app/Country";
import { camelToSnake } from "src/utils/stringUtils";
import ResourceLink from "./ResourceLink";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Item from "src/models/app/Item";
import Loading from "src/components/commons/Loading";
import ReactQuill from "react-quill";
import ResourceLinkName from "src/models/app/ResourceLinkName";

interface IFormInput {
  title: string;
  type: ItemType;
  subType: SubType;
  countryId: number;
  categoryId: number;
  coverImg: File;
}

export default function EditItem() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();

  const [infoUtils, setInfoUtils] = useState<{
    country: Country[] | null;
    category: Category[] | null;
  }>({
    country: null,
    category: null,
  });
  const [previewCoverImage, setPreviewCoverImage] = useState<
    File | string | null
  >(null);
  const [hasResourceLinks, setHasResourceLinks] = useState(false);
  const [resourceLinks, setResourceLinks] = useState<
    {
      type: ResourceLinkType;
      resource_link: string;
      resource_link_name_id: number | null;
      displayName: string;
    }[]
  >([]);
  const [isInvokeResourceLinkManagement, setIsInvokeResourceLinkManagement] =
    useState(false);
  const route = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [shortDescription, setShortDescription] = useState({
    value: "",
    errorMessage: "",
  });

  // start - effect
  useEffect(() => {
    fetchCategory();
    fetchCountry();
  }, []);

  useEffect(() => {
    fetchItemDetails();
  }, []);

  useEffect(() => {
    if (!hasResourceLinks && resourceLinks.length > 0) {
      setResourceLinks([]);
    }
  }, [hasResourceLinks]);

  useEffect(() => {
    if (infoUtils.country && infoUtils.country.length > 0) {
      setValue("countryId", infoUtils.country[0].id);
    }

    if (infoUtils.category && infoUtils.category.length > 0) {
      setValue("categoryId", infoUtils.category[0].id);
    }
  }, [infoUtils]);
  // end - effect

  // start - api
  const fetchItemDetails = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/items/${id}`);
      const item: Item = response.data;
      item.cover_img && setPreviewCoverImage(item.cover_img);
      setHasResourceLinks(item.resource_links.length > 0);
      setResourceLinks(
        item.resource_links.map((link) => {
          return {
            type: link.type,
            resource_link: link.resource_link,
            displayName:
              link.resource_link_name?.display_name ?? "Download Link",
            resource_link_name_id: link.resource_link_name?.id ?? null,
          };
        })
      );

      setShortDescription({
        ...shortDescription,
        value: item.short_description,
      });

      reset({
        categoryId: item.category_id,
        countryId: item.country_id,
        subType: item.sub_type as SubType,
        title: item.title,
        type: item.type as ItemType,
      });
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCountry = async () => {
    try {
      const response = await api.get("/countries");
      setInfoUtils((prevOptions) => ({
        ...prevOptions,
        country: response.data,
      }));
    } catch (error) {
      console.error("Error fetching country:", error);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await api.get("/categories");
      setInfoUtils((prevOptions) => ({
        ...prevOptions,
        category: response.data,
      }));
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };
  // end - api

  // start - utils
  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    if (!shortDescription.value) {
      setShortDescription({
        ...shortDescription,
        errorMessage: "Short Description is required.",
      });
      return;
    }

    if (hasResourceLinks && resourceLinks.length === 0) {
      toast.warning("Need to add resource links.");
      return;
    }

    const payload = new FormData();
    payload.append("short_description", shortDescription.value);

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const k = camelToSnake(key);
        if (key === "coverImg") {
          if (data[key][0]) {
            payload.append(k, data[key][0]);
          }
        } else {
          payload.append(k, data[key]);
        }
      }
    }

    try {
      payload.append("_method", "PUT");
      const res = await api.post(`/admin/items/${id}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      await api.post("/admin/resource-links", {
        item_id: res.data.id,
        resource_links: hasResourceLinks
          ? resourceLinks.map((resourceLink) => {
              return {
                ...resourceLink,
                resource_link_name_id: resourceLink.resource_link_name_id,
              };
            })
          : [],
        _method: "PUT",
      });
      toast.success("Item is edited.");
      route("/item");
    } catch (e) {
      console.log("e : ", e);
      toast.error("Something went wrong.");
    }
  };

  const handlePreviewCoverImage = (e: any) => {
    setPreviewCoverImage(e.target.files[0]);
  };
  // end - utils

  return (
    <>
      {isLoading ? (
        <Loading isFullScreen={true} />
      ) : (
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup
              label="Title"
              required
              formField={
                <Input
                  addOns={{
                    ...register("title", { required: "Title is required." }),
                  }}
                />
              }
              validationErrorMessage={errors.title?.message}
            />

            <FormGroup
              label="Short Description"
              formField={
                <ReactQuill
                  theme="snow"
                  value={shortDescription.value}
                  onChange={(e) =>
                    setShortDescription({
                      ...shortDescription,
                      value: e,
                      errorMessage: "",
                    })
                  }
                />
              }
              validationErrorMessage={shortDescription.errorMessage}
            />

            <FormGroup
              label="Type"
              formField={
                <Select addOns={{ ...register("type") }}>
                  <option value={ItemType.FILM}>Film</option>
                  <option value={ItemType.SERIES}>Series</option>
                </Select>
              }
            />

            <FormGroup
              label="Subtitle"
              formField={
                <Select addOns={{ ...register("subType") }}>
                  <option value={SubType.MM_SUB}>MMSUB</option>
                  <option value={SubType.NO_SUB}>NOSUB</option>
                </Select>
              }
            />

            <FormGroup
              label="Country"
              formField={
                <Select addOns={{ ...register("countryId") }}>
                  {infoUtils.country?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              }
            />

            <FormGroup
              label="Category"
              formField={
                <Select addOns={{ ...register("categoryId") }}>
                  {infoUtils.category?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              }
            />

            <FormGroup
              label="Cover Image"
              formField={
                <Input
                  type="file"
                  onChange={handlePreviewCoverImage}
                  addOns={{
                    ...register("coverImg", {}),
                  }}
                />
              }
              validationErrorMessage={errors.coverImg?.message}
            />

            {previewCoverImage && (
              <div>
                <hr />
                <h4>Preview</h4>
                <img
                  style={{ width: "300px", height: "250px" }}
                  src={
                    typeof previewCoverImage === "string"
                      ? previewCoverImage
                      : URL.createObjectURL(previewCoverImage)
                  }
                  alt="Preview"
                />
                <hr />
              </div>
            )}

            {/* start - Resource Link */}
            <FormGroup
              label="Is Free?"
              formField={
                <div>
                  <b>Free with links</b> &nbsp;&nbsp;
                  <Checkbox
                    onChange={() => setHasResourceLinks(!hasResourceLinks)}
                    checked={hasResourceLinks}
                  />
                </div>
              }
            />

            <div className="my-3">
              {resourceLinks.map((resourceLink, i) => (
                <span key={i} style={{ position: "relative" }}>
                  <b
                    onClick={() => {
                      setResourceLinks((prev) =>
                        prev.filter((_, pI) => pI !== i)
                      );
                    }}
                    style={{
                      position: "absolute",
                      top: "-20px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                  >
                    x
                  </b>
                  <span className="badge bg-primary me-3">
                    {resourceLink.displayName.slice(0, 30)}
                    {resourceLink.displayName.length > 30 ? "..." : ""}
                  </span>
                </span>
              ))}
            </div>
            <ResourceLink
              showModal={isInvokeResourceLinkManagement}
              setShowModal={setIsInvokeResourceLinkManagement}
              setResourceLinks={setResourceLinks}
            />
            {hasResourceLinks && (
              <div className="my-3">
                <Button
                  type="button"
                  onClick={() => setIsInvokeResourceLinkManagement(true)}
                >
                  Add Resource Link
                </Button>
              </div>
            )}
            {/* end - Resource Link */}

            <div className="my-3 d-flex justify-content-end">
              <Button type="submit">Submit</Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
